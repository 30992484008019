import React from "react";
import * as Styled from "./styled";
import { ReactComponent as FolderIcon } from "../../icons/folder.svg";
import { ReactComponent as EstimateIcon } from "../../icons/note.svg";
import { ReactComponent as ArrowIcon } from "../../icons/arrow-left.svg";
import { Item } from "./menuItem/styled";
import { useState } from "react";
import { selectActiveProject } from "../projects/projectsSlice";
import { useSelector, useDispatch } from "react-redux";
import { changeLoaderVisibility } from "../loader/loaderSlice";

export default function SideBarMenu() {
	const [isExtended, setIsExtended] = useState(false);
	const activeProject = useSelector(selectActiveProject);
	const dispatch = useDispatch();

	function extendHandler() {
		setIsExtended(!isExtended);
	}

	function closeTable() {
		dispatch(changeLoaderVisibility({ show: true }));
		localStorage.removeItem("table_id");
		window.location.reload();
	}

	function logOut() {
		dispatch(changeLoaderVisibility({ show: true }));
		localStorage.removeItem("accessToken");
		localStorage.removeItem("table_id");
		window.location.reload();
	}

	return (
		<Styled.SideBar $isExtended={isExtended}>
			<Styled.Wrapper $isExtended={isExtended}>
				<Styled.Controller $isExtended={isExtended}>
					{/* {!isExtended && <MenuIcon />} */}
					<ArrowIcon onClick={extendHandler} />
				</Styled.Controller>
				<Styled.Item>
					<Styled.ItemName>
						<FolderIcon />
						<span>{activeProject?.meta.name}</span>
					</Styled.ItemName>
					<button onClick={closeTable}>Close</button>
				</Styled.Item>
				<Styled.List>
					<Item>
						<EstimateIcon />
						<span>Estimate</span>
					</Item>
					{/* <Item>
							<EstimateIcon />
							<span>Estimate</span>
						</Item> */}
				</Styled.List>
				<Styled.Controller $isExtended={isExtended}>
					<button onClick={logOut}>Log Out</button>
					<ArrowIcon onClick={extendHandler} />
				</Styled.Controller>
				{/* <>
					<Styled.ItemName>
						<FolderIcon />
						<span>Project Name</span>
					</Styled.ItemName>
					<Styled.List>
						<Item>
							<EstimateIcon />
							<span>Estimate</span>
						</Item>
						<Item>
							<EstimateIcon />
							<span>Estimate</span>
						</Item>
					</Styled.List>
				</> */}
			</Styled.Wrapper>
		</Styled.SideBar>
	);
}
