import { createSlice } from "@reduxjs/toolkit";

const initialState = window.initialState = {
	show: false,
};

const loaderSlice = createSlice({
	name: "loader",
	initialState: initialState,
	reducers: {
		changeLoaderVisibility(state, action) {
			state.show = action.payload.show;
		},
	},
});

export function isLoaderVisible(state) {
	return state;
}

export function showLoaderEl(state) {
	return state.loader.show;
}

export const { changeLoaderVisibility } = loaderSlice.actions;

export default loaderSlice.reducer;
