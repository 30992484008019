import styled from "styled-components";

export const Wrapper = styled.div`
    position: ${({ show }) => show ? "absolute" : "initial"};
    bottom: 0;
    left: 0;
    background-color: #fff;
    padding: 0 4px;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    overflow-y: auto;
    height: ${({ show, theme }) => show ? "initial" : theme.height.column};
    max-height: 70%;
    box-shadow: 0px -3px 15px 0px rgba(184,184,184,0.82);
`;

export const ExpandButton = styled.div`
    background-color: transparent;
    border: none;
    cursor: pointer;
`;

export const Header = styled.div`
    display: flex;
    position: sticky;
    bottom: 0;
    right: 0;
    background-color: white;
    justify-content: right;
    align-items: center;
    gap: 20px;
    height: ${({ theme }) => theme.height.column};
    box-sizing: border-box;
`;

export const Total = styled.div`
    font-weight: 800;
`;

export const Templates = styled.div`
    overflow-y: auto;
    max-height: calc(100% - 15px);
`;

