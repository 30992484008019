import { createSlice } from "@reduxjs/toolkit";
import { PAGES } from "./constants";

const initialState = {
	currentPage: PAGES.INITIAL,
};

const pagesSlice = createSlice({
	name: "pages",
	initialState: initialState,
	reducers: {
		setPage(state, action) {
			const currentPage = action.payload.page;
			state.currentPage = currentPage;
		},
	},
});

export function selectPages(state) {
	return state.pages;
}

export const {
	setPage,
} = pagesSlice.actions;

export default pagesSlice.reducer;
