export const CONTEXT_MENU_CONSTANTS = {
	ROW: "ROW",
	TABS: "TABS",
};

export const HISTORY_MAX_ENTIRES = 20;

export const AUTO_SAVE_INTERVAL = 60 * 1000;

export const STORAGE_KEYS = {
	COPIED_ROWS_HIERARCHY: "CopyedRowHierarchy",
};
