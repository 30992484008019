import React, { memo } from "react";
import * as Styled from "./styled";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { updateCell } from "../../../tables/tablesSlice";

function DetailsCell({ title, rowIndex, columnIndex }) {
	const [value, setvalue] = useState(title);
	const dispatch = useDispatch();

	function changeHandler(event) {
		setvalue(event.target.value);
	}

	function submit() {
		dispatch(updateCell({ rowIndex, columnIndex, value }));
	}

	return (
		<Styled.Cell value={value} onChange={changeHandler} onBlur={submit}/>
	);
}

export default DetailsCell;
