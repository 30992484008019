import React, { memo } from "react";
import * as Styled from "./styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { toggleTreeExpandedStatus } from "../projectTreeSlice";
import { useDispatch } from "react-redux";
import { setFilteredRowIndex } from "../../../tables/tablesSlice";

function ListItem({
	value,
	show,
	padding,
	expanded,
	rowIndex,
	isParent,
	selected,
	rowId,
	tableId,
}) {
	const dispatch = useDispatch();

	function arrowClickHandler(event) {
		event.stopPropagation();
		dispatch(toggleTreeExpandedStatus({ id: rowId }));
	}

	function branchClickHandler() {
		dispatch(setFilteredRowIndex({ tableId, rowIndex }));
	}

	return (
		<Styled.ListItem
			padding={padding}
			show={show}
			isParent={isParent}
			onClick={branchClickHandler}
			selected={selected}
		>
			<FontAwesomeIcon
				icon={expanded ? faChevronDown : faChevronRight}
				size="xs"
				onClick={arrowClickHandler}
			/>
			<span>{value}</span>
		</Styled.ListItem>
	);
}

export default memo(ListItem);
