import { useEffect, useRef, useState } from "react";
import * as Styled from "./styled";
import { useDispatch, useSelector } from "react-redux";
import { changeLoaderVisibility, showLoaderEl } from "../../loader/loaderSlice";
import Loader from "../../loader/Loader";
import { Panel } from "../../panels/Panel";
import { addTab, selectPanels } from "../../panels/panelSlice";
import { createTable } from "../../tables/tablesSlice";
import { CONTENT_TYPE } from "../../panels/constants";
import SideBarMenu from "../../sideBarMenu/SideBarMenu";
import * as API from "../../../app/api";
import {
	createProject,
	selectActiveProjectId,
} from "../../projects/projectsSlice";
import { setExpenses } from "../../limitExpenses/limitExpensesSlice";

function Home() {
	const [loading, setLoading] = useState(false);
	const [ready, setReady] = useState(false);
	const readyRef = useRef(false);

	const panels = useSelector(selectPanels);
	const activeProjectId = useSelector(selectActiveProjectId);
	const dispatch = useDispatch();

	useEffect(() => {
		if (readyRef.current) return;
		dispatch(changeLoaderVisibility({ show: true }));
		// setLoading(true);
		API.fetchProject(activeProjectId)
			.then((project) => {
				const estimates = project.estimates;
				const expenses = project.expenses;
				dispatch(createTable({ tableId: project.id, data: estimates }));
				dispatch(
					addTab({
						contentId: activeProjectId,
						contentType: CONTENT_TYPE.PROJECT,
					}),
				);
				dispatch(setExpenses(expenses));
			})
			.finally(() => {
				readyRef.current = true;
				dispatch(changeLoaderVisibility({ show: false }));
				// setLoading(false);
				setReady(true);
			});
	}, [activeProjectId, dispatch]);

	// useEffect(function onOutsideClick() {
	// 	function unselect() {
	// 		dispatch(unselectAllCells({}));
	// 	}

	// 	document.addEventListener("click", unselect);

	// 	return () => {
	// 		document.removeEventListener("click", unselect);
	// 	};
	// }, [dispatch]);

	return (
		<Styled.Wrapper className="App">
			{ready && <SideBarMenu />}
			<Styled.Main>
				{panels.tabs.map((tabs, index) =>
					tabs.length ? (
						<Panel
							panels={panels}
							key={index}
							panelIndex={index}
							tabs={tabs}
							activeTab={panels.activeTabs[index]}
							panelsCount={panels.activeTabs.reduce(
								(acc, value) =>
									value !== null ? acc + 1 : acc,
								0,
							)}
						/>
					) : null,
				)}
			</Styled.Main>
			{/* <Styled.Footer $isExtended={isExtended}>
				{showExpanses && <LimitExpenses tableId={1} />}
			</Styled.Footer> */}
		</Styled.Wrapper>
	);
}

export default Home;
