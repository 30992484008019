
const baseMiddleware = store => next => action => {
	if (!action.payload) {
		action.payload = {};
	}
	action.payload._panels = store.getState().panels;

	next(action);
};
export default baseMiddleware;
