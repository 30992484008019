import styled from "styled-components";

export const TextEditor = styled.textarea`
	padding: 1px;
    outline: none;
    background-color: #fff;
    border: ${({ theme }) => `1px solid ${theme.colors.stroke}`};
    position: absolute;
    left: 0;
  	top:0;
  	color: ${({ theme }) => theme.colors.textPrimary};
    width: 100%;
    min-height: 100%;
	height: 23px;

    &:focus {
        border: ${({ theme }) => `2px solid ${theme.colors.stroke}`};
    }
    z-index: ${({ theme }) => theme.zIndexes.z20};
`;
