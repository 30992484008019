import styled from "styled-components";

export const Wrapper = styled.div`
	height: ${({ theme }) => theme.height.ribbons};
	display: flex;
	padding: ${({ theme }) => `${theme.spacing.s} ${theme.spacing.m}`};
	justify-content: space-between;
	align-items: center;
	box-sizing: border-box;
`;

export const LeftSection = styled.div`
	display: flex;
	align-items: center;
	gap: ${({ theme }) => `${theme.spacing.s} ${theme.spacing.xs}`};

	input {
		all: unset;
		width: 100px;
		font-size: ${({ theme }) => theme.fontSize.s};
		padding: ${({ theme }) => theme.spacing.xs};
		border: ${({ theme }) => `1px solid ${theme.colors.stroke}`};
		border-radius: ${({ theme }) => theme.radius.xs};
	}

	svg {
		height: 28px !important;
		width: auto !important;
	}
`;

export const RightSection = styled.div`
	display: flex;
	gap: ${({ theme }) => `${theme.spacing.s} ${theme.spacing.xs}`};

	svg {
		height: 28px !important;
		width: auto !important;
	}

	input {
	  display: none;
	}
`;
