import React, { useRef, useState } from "react";
import * as Styled from "./styled";
import Checkbox from "./checkbox/Checkbox";

export default function Select({ data, onChange, values, children }) {
	const [show, setShow] = useState(false);
	const listRef = useRef();

	function toggleSelect(value) {
		let nextSelectedValues = [...values];
		let index = nextSelectedValues.indexOf(value);
		if(index > -1) {
			nextSelectedValues.splice(index, 1);
		} else {
			nextSelectedValues.push(value);
		}
		onChange(nextSelectedValues);
	}

	function toggleShow(e) {
		setShow(!show);
	}

	return (
		<Styled.Wrapper ref={listRef}>
			<div style={{ display: "inline" }} onClick={e => toggleShow(e)}>
				{children}
			</div>
			<Styled.List show={show}>
				{data.map(res => (
					<Checkbox
						key={res.value}
						title={res.label}
						isChecked={values.includes(res.value)}
						onCheck={() => toggleSelect(res.value)}
					/>
				))}
			</Styled.List>
		</Styled.Wrapper>
	);
}
