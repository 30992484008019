import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import * as Styled from "./styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import Template from "./template/Template";
import useTemplates from "../../hooks/useTemplates";
import { selectTableData } from "../../features/tables/tablesSlice";

export default function LimitExpenses({ tableId, show, setShow }) {
	const { filteredRowIndex } = useSelector((state) => selectTableData(state, tableId));

	const { calculateTemplateTotals } = useTemplates({ tableId, rowIndexes: [ filteredRowIndex ] });

	function toggleShow() {
		setShow(!show);
	}

	const total = useMemo(() => {
		let sum = 0;
		calculateTemplateTotals.forEach(calculateTemplateTotal => {
			calculateTemplateTotal.templates.forEach(template => {
				sum += template.rows[template.rows.length - 1].total;
			});
		});
		// for(let i = 0; i<calculateTemplateTotals.length; i++) {
		// 	for(let k = 0; k<calculateTemplateTotals[i].templates.length; k++) {
		// 		sum+= calculateTemplateTotals[i].templates[calculateTemplateTotals[i].templates[k]];
		// 	}
		// }

		return sum.toFixed(2);
	}, [calculateTemplateTotals]);

	// const uniqueTemplates = useMemo(() => {
	// 	let templatesComp = [];
	// 	if(show) {
	// 		for(let i in uniqueTemplateIndexesByRows) {
	// 			const templateIndexes = uniqueTemplateIndexesByRows[i];
	// 			templateIndexes.forEach((templateIndex) => {
	// 				calculateTemplateTotals.forEach((templateTotal) => {
	// 					if(templateTotal.rowIndex === i) {
	// 						templatesComp.push(<Template tableId={tableId} rowTotals={templateTotal.templates[templateIndex].rows} allowSelect={true} key={templateTotal.templates[templateIndex].name} rowIndex={filteredRowIndex} templateIndex={templateIndex} />);
	// 					}
	// 				});
	// 			});
	// 		}
	// 	}

	// 	// [
	// 	// 	{
	// 	// 		rowIndex: 1,
	// 	// 		templates: [
	// 	// 			{
	// 	// 				name: "",
	// 	// 				index: 1,
	// 	// 				rows: [
	// 	// 					{
	// 	// 						description:"",
	// 	// 						percentage:0,
	// 	// 						fixedValue:0,
	// 	// 						amount: 0,
	// 	// 					}
	// 	// 				]
	// 	// 			}
	// 	// 		]
	// 	// 	}
	// 	// ]

	// 	return templatesComp;
	// }, [calculateTemplateTotals, filteredRowIndex, show, tableId, uniqueTemplateIndexesByRows]);

	return (
		<Styled.Wrapper show={show}>
			<Styled.Templates>
				{
					calculateTemplateTotals.map((templateTotal) => {
						return templateTotal.templates.map((template, index) => {
							return <Template
								currentTemplate={template}
								tableId={tableId}
								templateRowIndex={templateTotal.rowIndex}
								templateRows={template.rows}
								allowSelect={true}
								key={template.name}
								rowIndex={filteredRowIndex}
								templateIndex={template.index}
							/>;
						});
					})
					// show && uniqueTemplateIndexesByRows.map((templateIndex, i) => (
					// 	<Template tableId={tableId} rowTotals={calculateTemplateTotals[i].templates[templateIndex].rows} allowSelect={i === 0} key={templates[templateIndex].name} rowIndex={filteredRowIndex} templateIndex={templateIndex} />
					// ))
				}
			</Styled.Templates>
			<Styled.Header>
				<Styled.Total>Total - {total}</Styled.Total>
				<Styled.ExpandButton onClick={toggleShow}>
					<FontAwesomeIcon icon={show ? faCaretDown : faCaretUp} size="xl" />
				</Styled.ExpandButton>
			</Styled.Header>
		</Styled.Wrapper>
	);
}
