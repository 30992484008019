import styled from "styled-components";

export const Cell = styled.input`
    all: unset;
    border-bottom: 1px solid ${({ theme }) => theme.colors.stroke};
    border-left: 1px solid ${({ theme }) => theme.colors.stroke};
    background-color: #fff;
    padding: ${({ theme }) => theme.spacing.s};
    width: 100%;
`;
