import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./app/App";
import store from "./app/store";
import { Provider } from "react-redux";
import { NotificationContainer } from "./common/ui/NotificationContainer/NotificationContainer";
import { ContextMenuContextProvider } from "./contexts/contextMenuContext";
import ThemeContextProvider from "./contexts/themeContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	// <React.StrictMode>
	<ThemeContextProvider>
		<Provider store={store}>
			<ContextMenuContextProvider>
				<NotificationContainer />
				<App />
			</ContextMenuContextProvider>
		</Provider>
	</ThemeContextProvider>
	// </React.StrictMode>
);
