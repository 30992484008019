import { createSlice } from "@reduxjs/toolkit";
import { generateEmptyTemplateRow } from "./helpers";

const limitExpensesSlice = createSlice({
	name: "limitExpenses",
	initialState: {
		templates: [
			{
				name: "Default template",
				rows: [
					{ description: "", percentage: 0, fixedValue: 0, from: [], filter: [] },
				],
			},
		],
		bindings: {},
	},
	reducers: {
		setExpenses(state, action) {
			let expenses = action.payload;
			state.templates = expenses.templates;
			state.bindings = expenses.bindings;
		},
		createTemplate(state, action) {
			let { name } = action.payload;
			state.templates.push({ name, rows: [generateEmptyTemplateRow(0)] });
		},
		addTemplateRow(state, action) {
			let { templateIndex, index } = action.payload;
			state.templates[templateIndex].rows.push(generateEmptyTemplateRow(index));
		},
		removeTemplateRow(state, action) {
			let { templateIndex, rowIndex } = action.payload;
			let template = state.templates[templateIndex];
			template.rows.forEach(row => {
				let indexToRemove;
				row.from.forEach((linked, index) => {
					if (linked === rowIndex) {
						indexToRemove = index;
					} else if (linked > rowIndex) {
						row.from[index]--;
					}
				});
				if (indexToRemove > -1) {
					row.from.splice(indexToRemove, 1);
				}
				if (row.from.length === 0) {
					row.from.push(0);
				}
			});
			template.rows.splice(rowIndex, 1);
		},
		removeTemplate(state, action) {
			let { templateIndex } = action.payload;
			if (templateIndex === 0) return;
			state.templates.splice(templateIndex, 1);
			for (let i in state.bindings) {
				if (state.bindings[i] === templateIndex) {
					delete state.bindings[i];
				} else if(state.bindings[i] > templateIndex) {
					state.bindings[i]--;
				}
			}
		},
		updateTemplateRow(state, action) {
			let { templateIndex, rowIndex, prop, value } = action.payload;
			let row = state.templates[templateIndex].rows[rowIndex];
			row[prop] = value;
			if (prop === "filter") {
				if (value.length) {
					row.from = [];
				} else {
					row.from = [0];
				}
			}
		},
		assignTemplate(state, action) {
			let { selectedRowId, templateIndex } = action.payload;
			state.bindings[selectedRowId] = templateIndex;
		},
		changeTemlateName(state, action) {
			let { templateIndex, changedName } = action.payload;
			state.templates[templateIndex].name = changedName;
		},
	},
});

export function selectTemplateBindings(state) {
	return state.limitExpenses.bindings;
}

export function selectLimitExpenses(state) {
	return state.limitExpenses;
}

export const { setExpenses, addTemplateRow, createTemplate, removeTemplateRow, removeTemplate, updateTemplateRow, assignTemplate, changeTemlateName } = limitExpensesSlice.actions;

export default limitExpensesSlice.reducer;
