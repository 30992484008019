import { useDispatch } from "react-redux";
import { SELECTION_CONTEXT } from "../../selection/constants";
import { highlight } from "../../selection/selectionSlice";
import Cell from "../Cell";
import { COLUMN_INDEXES } from "../constants";
import { getAncestorsCount } from "../helpers";
import * as Styled from "./styled";
import React, { useCallback } from "react";
import { clearRowSelection, selectCell, selectRow, toggleRowExpandedStatus, unselectRow } from "../tablesSlice";
import FirstCell from "../FirstCell";

function Row({ className, row, columns, props, rowIndex, contextMenuHandler, uniqueCategories, tableId, selected, selectedCell, isParent, filteredRowIndex }) {
	const dispatch = useDispatch();
	const rowId = row[COLUMN_INDEXES.ID];

	const toggleExpanded = useCallback(() => {
		dispatch(toggleRowExpandedStatus({ id: rowId }));
	}, [dispatch, rowId]);

	function toggleSelection(event) {
		switch (selected) {
		case 0:
			dispatch(selectRow({ rowIndex, withChildren: true, combine: event.shiftKey }));
			dispatch(selectCell({ rowIndex: null, cellIndex: null, tableId }));
			break;
		case 1:
			if(isParent) {
				dispatch(selectRow({ rowIndex, withChildren: false, combine: event.shiftKey }));
			} else {
				dispatch(unselectRow({ rowIndex }));
			}
			break;
		case 2:
			dispatch(unselectRow({ rowIndex }));
			break;

		default:
			break;
		}
	}

	function selectCellHandler(event, cellIndex) {
		dispatch(selectCell({ rowIndex, cellIndex, tableId }));
		dispatch(clearRowSelection({}));

		event.stopPropagation();
	}

	return (
		<Styled.Row className={className} onContextMenu={e => contextMenuHandler(e, rowIndex)} height={props.height}>
			<FirstCell rowIndex={rowIndex} selected={selected} onSelect={toggleSelection} />
			{columns.map((column, columnIndex) => (
				column.show && (
					<Cell
						align={column.align}
						key={column.title + "_" + row[COLUMN_INDEXES.ID]}
						columnIndex={columnIndex}
						column={column}
						cellProps={row[column.cellIndex]}
						nestingLevel={column.expandable && getAncestorsCount(row, tableId, filteredRowIndex)}
						expanded={column.expandable && props.expanded}
						showArrow={column.expandable && isParent}
						edited={row[column.cellIndex].e}
						uniqueCategories={uniqueCategories}
						tableId={tableId}
						rowIndex={rowIndex}
						row={row}
						toggleExpanded={toggleExpanded}
						onSelect={(event) => selectCellHandler(event, column.cellIndex)}
						selected={selectedCell.rowIndex === rowIndex && selectedCell.cellIndex === column.cellIndex}
					/>
				)
			))}
		</Styled.Row>
	);
}

export default React.memo(Row);
