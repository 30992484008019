const columnsMiddleware = store => next => action => {
	next(action);
	switch (action.type) {
	case "tables/updateCell": {

		break;
	}
	default:
		break;
	}
};
export default columnsMiddleware;
