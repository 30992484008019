import { getActiveContentId } from "../../features/panels/helpers";
import { history } from "../modules/history";

const actionTypes = [
	"tables/addRow",
	"tables/removeRow",
	"tables/replaceRow",
	"tables/updateCell",
	"tables/upRows",
	"tables/downRows",
	"tables/indentRows",
	"tables/outdentRows",
];

const historyMiddleware = store => next => action => {
	let selectedContentId = getActiveContentId();
	if(!selectedContentId) {
		next(action);

		return;
	}

	if(history.isEmpty(selectedContentId)) {
		history.push(selectedContentId, extractStateParts(selectedContentId, store.getState()));
	}
	next(action);

	if(actionTypes.includes(action.type)) {
		history.push(selectedContentId, extractStateParts(selectedContentId, store.getState()));
	}
};

function extractStateParts(id, state) {
	return {
		rows: [...state.tables[id].rows],
	};
}

export default historyMiddleware;
