import * as Styled from "./styled";
import { useState } from "react";
import logo from "../../../assets/icon.png";
import Button from "../../button";
import { setPage } from "../pagesSlice";
import { useDispatch } from "react-redux";
import { PAGES } from "../constants";
import { ReactComponent as BackIcon } from "../../../icons/arrow-left.svg";
import * as API from "../../../app/api";
import { useSnackbar } from "notistack";
import Loader from "../../loader/Loader";
import { changeLoaderVisibility } from "../../loader/loaderSlice";

export default function Login() {
	const [email, setEmail] = useState("david@gmail.com");
	const [password, setPassword] = useState("12345678");
	const [rememberMe, setRememberMe] = useState(false);
	const [loading, setLoading] = useState(false);
	const { enqueueSnackbar } = useSnackbar();

	const dispatch = useDispatch();

	function navigateToRegister() {
		dispatch(setPage({ page: PAGES.REGISTER }));
	}

	function navigateToInitial() {
		dispatch(setPage({ page: PAGES.INITIAL }));
	}

	function login() {
		dispatch(changeLoaderVisibility({ show: true }));
		API.login({ email, password })
			.then((res) => {
				dispatch(setPage({ page: PAGES.DASHBOARD }));
				enqueueSnackbar("You are logged in", {
					variant: "success",
				});
			})
			.catch((error) => {
				enqueueSnackbar(error.response.data.message, {
					variant: "error",
				});
			});
	}

	return (
		<Styled.Wrapper>
			<Styled.Container>
				<div className="logo">
					<img src={logo} alt="logo" />
				</div>
				<div className="heading-text">Sign in to your account</div>
				<Styled.Inputs>
					<>
						<Styled.Input
							type="email"
							placeholder={"Email Address"}
							value={email}
							onChange={(e) => setEmail(e.target.value)}
						/>
						<Styled.Input
							type="password"
							placeholder={"Password"}
							value={password}
							onChange={(e) => setPassword(e.target.value)}
						/>
					</>
				</Styled.Inputs>
				<div className="remember-me">
					<input
						type="checkbox"
						checked={rememberMe}
						onChange={() => setRememberMe(!rememberMe)}
					/>
					<span>Remember Me</span>
				</div>
				<div className="submit">
					<Button title={"Log In"} onClick={login} />
				</div>
				<div className="have-account">
					<div>
						<span>Don’t have an account?</span>
						<span onClick={navigateToRegister}>
							Sign Up for free
						</span>
					</div>
					<div>
						<span></span>
						<span>Forgot password?</span>
					</div>
					<BackIcon onClick={navigateToInitial} />
				</div>
			</Styled.Container>
		</Styled.Wrapper>
	);
}
