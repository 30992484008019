import styled from "styled-components";

export const Button = styled.button`
    all: unset;
    padding: ${({ theme }) => `${theme.spacing.s} 0`};
    text-align: center;
    background-color: #0266EF;
    border-radius: ${({ theme }) => theme.radius.xs};
    font-size: ${({ theme }) => theme.fontSize.m};
    color: #fff;
`;
