import styled from "styled-components";

export const Wrapper = styled.div`
	position: absolute;
  	top: 0;
  	left: 0;
  	width: 100vw;
  	height: 100vh;
  	display: flex;
  	justify-content: center;
  	align-items: center;
  
  	div {
		position: absolute;
		width: 100%;
		height: 100%;
		z-index: ${({ theme }) => theme.zIndexes.z20 };
		opacity: 0.2;
		background-color: ${({ theme }) => theme.colors.primary };
    }
`;

export const Loader = styled.span`
    width: 48px;
    height: 48px;
    border: 3px solid #19376D;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  
	&::after {
	    content: '';
	    box-sizing: border-box;
	    position: absolute;
	    left: 50%;
	    top: 50%;
	    transform: translate(-50%, -50%);
	    width: 40px;
	    height: 40px;
	    border-radius: 50%;
	    border: 3px solid;
	    border-color: coral transparent;
	}

	@keyframes rotation {
	    0% {
	      transform: rotate(0deg);
	    }
	    100% {
	      transform: rotate(360deg);
	    }
	}

`;

