export const SELECTED_CONTENT_TYPE = {
	NONE: "none",
	CELL: "cell",
	ROW: "row",
	ROWS: "rows",
};

export const SELECTION_CONTEXT = {
	TABLE: "table",
};

