import React, { useEffect, useMemo, useRef, useState } from "react";
import * as Styled from "./styled";
import { useDispatch, useSelector } from "react-redux";
import { selectSelectedRowIndex } from "../../selection/selectionSlice";
import { COLUMN_INDEXES } from "../../tables/constants";
import { addTemplateRow, assignTemplate, changeTemlateName, createTemplate, removeTemplate, removeTemplateRow, updateTemplateRow } from "../limitExpensesSlice";
import { generateNestedRows, getChildren, getTotalCostCellIndex, isParent } from "../../tables/helpers";
import { findRowsTemplateIndex } from "../helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink, faLinkSlash, faTrash, faEdit } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as ArrowIcon } from "../../../icons/arrow-left.svg";
import Select from "../../select/Select";
import { selectTableRows, selectUniqueCategories } from "../../tables/tablesSlice";
import SelectTemplate from "./select/SelectTemplate";
import { getUniqueCategories } from "../../tables/helpers";

export default function Template({ tableId, templateRowIndex, templateRows, templateIndex, allowSelect, rowTotals }) {
	const [selectedTemplateRowIndex, setSelectedTemplateRowIndex] = useState(null);
	const { templates, bindings } = useSelector(state => state.limitExpenses);
	const [newTemplateName, setNewTemplateName] = useState("");
	const [showTemplateNameInput, setShowTemplateNameInput] = useState(false);
	const [isEditing, setIsEditing] = useState(false);
	const templateNameInput = useRef();

	let rows = useSelector(state => selectTableRows(state, tableId));
	let uniqueCategories = useSelector(state => selectUniqueCategories(state, tableId));

	const dispatch = useDispatch();

	let selectedRowId = useMemo(() => {
		if(Number(templateRowIndex) === -1) return -1;

		return rows[templateRowIndex][0];
	}, [templateRowIndex, rows]);

	function addRow() {
		const index = templates[templateIndex].rows.length - 1;
		dispatch(addTemplateRow({ templateIndex, index }));
	}

	function removeRow(rowIndex) {
		if (rowIndex === 0) {
			alert("You can't delete template's first row");

			return;
		}
		if (selectSelectedRowIndex) {
			setSelectedTemplateRowIndex(null);
		}
		dispatch(removeTemplateRow({ templateIndex, rowIndex }));
	}

	function selectTemplate(e) {
		if (e.target.value === "") {
			createNewTemplate();
		} else {
			dispatch(assignTemplate({ selectedRowId, templateIndex: +e.target.value }));
		}
	}

	function removeSelectedTemplate() {
		if (templateIndex === 0) {
			alert("You can't delete default template");

			return;
		}
		if (selectedTemplateRowIndex) {
			setSelectedTemplateRowIndex(null);
		}
		dispatch(removeTemplate({ templateIndex }));
	}

	function unFocus(e) {
		if (e.key === "Enter") {
			e.target.blur();
		}
	}

	function createNewTemplate() {
		if (selectedTemplateRowIndex) {
			setSelectedTemplateRowIndex(null);
		}
		if (newTemplateName.length === 0) {
			setShowTemplateNameInput(false);

			return;
		}

		if(templates.map(temp => temp.name).includes(newTemplateName)) {
			alert("This Template Already Exists!");

			return;
		}
		setShowTemplateNameInput(false);
		dispatch(createTemplate({ selectedRowId, name: newTemplateName }));
	}

	function enterTempleteName(e) {
		setNewTemplateName(e.target.value);
	}

	function editTemplateName() {
		setShowTemplateNameInput(true);
		setNewTemplateName(templates[templateIndex].name);
		setIsEditing(true);
	}

	function renameExistingTemplate() {
		if (newTemplateName.length === 0) {
			setShowTemplateNameInput(false);

			return;
		}
		if(templates.map(temp => temp.name).includes(newTemplateName) && templates[templateIndex].name !== newTemplateName) {
			alert("This Template Already Exists!");

			return;
		}
		setIsEditing(false);
		setNewTemplateName("");
		setShowTemplateNameInput(false);
		dispatch(changeTemlateName({ templateIndex, changedName: newTemplateName }));
	}

	function selectTemplateRow(index) {
		setSelectedTemplateRowIndex(index);
	}

	function toggleAttach(rowIndex) {
		let value = [...templates[templateIndex].rows[selectedTemplateRowIndex].from];
		let i = value.indexOf(rowIndex);
		if (i > -1) {
			if (value.length === 1) {
				alert("you can not detach all rows!");

				return;
			}
			value.splice(i, 1);
		} else {
			value.push(rowIndex);
		}
		updateProp(selectedTemplateRowIndex, "from", value);
	}

	function updateProp(rowIndex, prop, value) {
		dispatch(updateTemplateRow({ templateIndex: templateIndex, rowIndex, prop, value }));
	}

	function showAttachButton(index) {
		if (!selectedTemplateRowIndex) return false;
		if (index >= selectedTemplateRowIndex) return false;
		if (templates[templateIndex].rows[selectedTemplateRowIndex].filter.length) return false;

		return true;
	}

	useEffect(() => {
		if(templateNameInput.current) {
			templateNameInput.current.focus();
		}
	}, [showTemplateNameInput]);

	return (
		<div>
			{
				<Styled.Wrapper>
					<Styled.SelectContainer>
						{
							allowSelect ?
								<SelectTemplate templates={templates} templateIndex={templateIndex} selectedRowId={selectedRowId}/> :
								<div>{templates[templateIndex].name}</div>
						}
					</Styled.SelectContainer>
					<Styled.Row>
						<div>Description</div>
						<div>Percentage</div>
						{/* <div>Fixed Value</div> */}
						<div>Amount</div>
						<div>Total</div>
						<div>Filter</div>
					</Styled.Row>
					{templateRows.map((row, i) => {
						let highlighted = selectedTemplateRowIndex && templates[templateIndex].rows[selectedTemplateRowIndex].from.includes(i);

						return (
							<Styled.Row
								key={i}
								selected={i === selectedTemplateRowIndex}
								highlighted={highlighted}
							>
								<div onClick={() => selectTemplateRow(i)}>
									{i !== 0 &&
										<input
											onChange={e => updateProp(i, "description", e.target.value)}
											value={row.description}
											onKeyUp={unFocus}
										/>
									}
								</div>
								<div>
									{i !== 0 &&
										<>
											<input
												onChange={e => updateProp(i, "percentage", +e.target.value)}
												value={row.percentage}
												onKeyUp={unFocus}
											/>%
										</>
									}
								</div>
								{/* <div>
									{i !== 0 &&
										<input
											onChange={e => updateProp(i, "fixedValue", +e.target.value)}
											value={row.fixedValue}
											onKeyUp={unFocus}
										/>
									}
								</div> */}
								<div>{i !== 0 && row.amount.toFixed(2)}</div>
								<div>{row.total.toFixed(2)}</div>
								<Styled.FilterCell>
									<Select values={row.filter} data={getUniqueCategories(rows).map(category => ({ label: category || "Other", value: category }))} onChange={selectedValues => updateProp(i, "filter", selectedValues)}>
										<span className="link">{row.filter.length} Filter{row.filter.length !== 1 ? "s" : ""}</span>
									</Select>
								</Styled.FilterCell>
								<div>
									{i !== 0 && i === selectedTemplateRowIndex ?
										<button onClick={() => removeRow(i)}>
											<FontAwesomeIcon icon={faTrash} opacity={0.7} />
										</button>
										: null
									}
									{showAttachButton(i) ?
										<button onClick={() => toggleAttach(i)}>
											<FontAwesomeIcon icon={highlighted ? faLink : faLinkSlash} color={highlighted ? "blue" : "gray"} />
										</button> :
										null
									}
								</div>
							</Styled.Row>
						);
					})}
					<Styled.Tools>
						<Styled.Addrow>
							<button onClick={addRow}>+ Add Row</button>
						</Styled.Addrow>
					</Styled.Tools>
				</Styled.Wrapper>
			}
		</div>
	);
}
