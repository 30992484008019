import { CELL_TEXT_ALIGN, COLUMN_STATUSES } from "./constants";

export const defaultColumns = [
	{ cellIndex: 2, title: "Position", type: "text", show: true, editable: false, width: 100, align: CELL_TEXT_ALIGN.START, status: COLUMN_STATUSES.INITIAL },
	{ cellIndex: 3, title: "Code", type: "text", show: true, editable: true, width: 100, align: CELL_TEXT_ALIGN.START, status: COLUMN_STATUSES.INITIAL },
	{ cellIndex: 4, title: "Item Name", type: "text", show: true, editable: true, expandable: true, width: 320, align: CELL_TEXT_ALIGN.START, status: COLUMN_STATUSES.INITIAL },
	{ cellIndex: 5, title: "Category", type: "text", show: true, editable: true, width: 100, align: CELL_TEXT_ALIGN.CENTER, status: COLUMN_STATUSES.INITIAL },
	{ cellIndex: 6, title: "Unit", type: "text", show: true, editable: true, width: 100, align: CELL_TEXT_ALIGN.CENTER, status: COLUMN_STATUSES.INITIAL },
	{ cellIndex: 7, title: "Quantity", type: "number", show: true, editable: true, width: 100, align: CELL_TEXT_ALIGN.END, status: COLUMN_STATUSES.INITIAL },
	{ cellIndex: 8, title: "Quantity absolut", type: "number", defaultValue: 1, show: true, editable: true, width: 100, align: CELL_TEXT_ALIGN.END, status: COLUMN_STATUSES.INITIAL },
	{ cellIndex: 9, title: "Unit Cost", type: "number", show: true, editable: true, width: 100, align: CELL_TEXT_ALIGN.END, status: COLUMN_STATUSES.INITIAL },
	{ cellIndex: 10, title: "Total Cost", type: "number", show: true, editable: false, width: 100, align: CELL_TEXT_ALIGN.END, status: COLUMN_STATUSES.INITIAL },
];
