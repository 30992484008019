import React, { memo } from "react";
import * as Styled from "./styled";
import { condenseAllRows, expandAllRows, expendByLevels } from "../../tables/tablesSlice";
import { useDispatch } from "react-redux";

function Levels({ levelNum }) {
	const dispatch = useDispatch();

	let levels = Array.from({ length: levelNum }, (_, index) => index + 1);

	function condenseHandler() {
		dispatch(condenseAllRows());
	}

	function expandHandler() {
		dispatch(expandAllRows());
	}

	function expandLevelHandler(level) {
		dispatch(expendByLevels({ level }));
	}

	return (
		<Styled.Wrapper>
			<div onClick={expandHandler}>Expand all</div>
			<div onClick={condenseHandler}>Condense all</div>
			{
				levels.map(level => <div key={level} onClick={() => expandLevelHandler(level)}>Level {level}</div>)
			}
		</Styled.Wrapper>
	);
}

export default Levels;
