import styled from "styled-components";
import Row from "./Row";

export const Wrapper = styled.div`
	height: ${({ theme, showExpenses }) => `calc(100% - ${theme.height.tabs} - ${theme.height.ribbons} - ${theme.height.column})`};
	overflow: auto hidden;
`;

export const Window = styled.div`
	height: ${({ headerHeight }) => `calc(100% - ${headerHeight})`};
	width: ${({ width }) => width + "px"};
	overflow: hidden auto;
	position: relative;
`;

export const ContentBox = styled.div`
	height: ${({ height }) => height + "px"};
	width: ${({ width }) => width + "px"};
`;

export const PositionedRow = styled(Row)`
	position: absolute;
	top: ${({ top }) => top + "px"};
	background-color: ${({ theme, selected }) => selected !== 0 ? "#F5F5F5" : ""};
`;
