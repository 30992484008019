import * as Styled from "./styled";
import { useState } from "react";

function ToolTip({ text, children }) {
	const [isVisible, setIsVisible] = useState(false);

	const handleMouseEnter = () => {
		setIsVisible(true);
	};

	const handleMouseLeave = () => {
		setIsVisible(false);
	};

	return (
		<Styled.TooltipContainer
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
			onClick={() => setIsVisible(false)}
		>
			{children}

			{
				isVisible &&
				<Styled.TooltipContent position={true}>
					{text}
				</Styled.TooltipContent>
			}
		</Styled.TooltipContainer>
	);
}

export default ToolTip;

