import React, { useMemo } from "react";
import ListItem from "./ListItem";
import * as Styled from "./styled";
import { useSelector } from "react-redux";
import { selectTableData } from "../../tables/tablesSlice";
import { branchShouldBeVisible, isParent } from "../../tables/helpers";
import { selectProjectTreeData } from "./projectTreeSlice";
import { COLUMN_INDEXES } from "../../tables/constants";
import { getActiveContentId } from "../../panels/helpers";

export default function List() {
	let tableId = getActiveContentId();
	const { rows, filteredRowIndex } = useSelector(state => selectTableData(state, tableId));
	const { expandedRowIds } = useSelector(selectProjectTreeData);

	const visibleRows = useMemo(() => {
		let res = [];
		let startRowIndex = 0;
		let lastRowIndex = rows.length - 1;

		for (let rowIndex = startRowIndex; rowIndex <= lastRowIndex; rowIndex++) {
			let row = rows[rowIndex];
			res.push({ rowIndex, row });
			if (!expandedRowIds[row[COLUMN_INDEXES.ID]]) {
				let childIndex = rowIndex + 1;
				while (childIndex < rows.length && rowIndex <= rows[childIndex][COLUMN_INDEXES.PARENT_INDEX]) {
					childIndex++;
				}
				rowIndex = childIndex - 1;
			}
		}

		return res;
	}, [expandedRowIds, rows]);

	return (
		<Styled.List>
			<ListItem value="Progect Name" show={true} tableId={tableId} rowIndex={-1}></ListItem>
			{visibleRows.map(({ row, rowIndex }) => (
				<ListItem
					key={row[COLUMN_INDEXES.ID]}
					rowIndex={rowIndex}
					rowId={row[COLUMN_INDEXES.ID]}
					expanded={expandedRowIds[row[COLUMN_INDEXES.ID]] === 1}
					isParent={isParent(rowIndex)}
					value={row[COLUMN_INDEXES.ITEM_NAME].v}
					show={branchShouldBeVisible(rows, expandedRowIds, row)}
					padding={(row[COLUMN_INDEXES.POSITION].v.split(".").length - 1) * 12}
					selected={rowIndex === filteredRowIndex}
					tableId={tableId}
				/>
			))}

		</Styled.List>
	);
}
