import Login from "./auth/Login";
import Register from "./auth/Register";
import InitialPage from "./auth/initialPage/InitialPage";
import { PAGES } from "./constants";
import Home from "./home";
import Dashboard from "./dashboard";

export const routes = {
	[PAGES.INITIAL]: <InitialPage />,
	[PAGES.LOGIN]: <Login />,
	[PAGES.REGISTER]: <Register />,
	[PAGES.HOME]: <Home />,
	[PAGES.DASHBOARD]: <Dashboard/>,
};
