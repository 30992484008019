import styled from "styled-components";

export const Row = styled.div`
    display: flex;
    height: ${({ height }) => `${height}px`};

    :hover {
		background-color: ${({ theme }) => theme.colors.rowHover};
	  }

    ::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 1px;
        background-color: ${({ theme }) => theme.colors.stroke};
        left: 0;
        bottom: 0;
    }
`;
