import { createSlice } from "@reduxjs/toolkit";
import { SELECTED_CONTENT_TYPE } from "./constants";

const initialState = window.initialState = {
	highlightData: {
		columnIndex: null,
		column: null,
		rowIndex: null,
		firstRowIndex: null,
		lastRowIndex: null,
		context: null,
		contextElementId: null,
		keyupHandler: null,
		value: null,
	},
	clipboard: {
		content: null,
		contentType: SELECTED_CONTENT_TYPE.NONE,
	},
};

const selectionSlice = createSlice({
	name: "selection",
	initialState,
	reducers: {
		addToClipboard(state, action) {
			state.clipboard = action.payload;
		},
		highlight(state, action) {
			state.highlightData = action.payload;
		},
		clearHighlight(state) {
			state.highlightData = { ...initialState.highlightData };
		},
	},
});

export function selectSelectionData(state) {
	return state.selection;
}

export function selectSelectedRowIndex(state) {
	return state.selection.highlightData.firstRowIndex;
}

export const { addToClipboard, highlight, clearHighlight } = selectionSlice.actions;

export default selectionSlice.reducer;

