import { COLUMN_INDEXES, DYNAMIC_COLUMNS_OFFSET } from "./constants";
import { isParent } from "./helpers";

export function updateTableWithFormulas(tableData, lastUpdateData = {}) {
	const { rows, uniqueCategories, columns } = tableData;
	rows.forEach((_row, rowIndex) => {
		calculatePositionNumber(rows, rowIndex);
		calculateQuantity(rows, rowIndex, lastUpdateData);
		calculateQuantityAbsolut(rows, rowIndex, lastUpdateData);
		calculateTotalCosts(rows, rowIndex, uniqueCategories, columns);
	});

	return { rows: rows };
}

function calculateTotalCosts(rows, rowIndex, uniqueCategories, columns) {
	let row = rows[rowIndex];
	let startCellIndex = columns[DYNAMIC_COLUMNS_OFFSET - 1].cellIndex + 1;
	let totalCostIndex = startCellIndex + uniqueCategories.length * 2 + 1;
	let unitCostIndex = startCellIndex + uniqueCategories.length * 2;

	if (isParent(rowIndex, rows)) {
		for (let i = startCellIndex; i < startCellIndex + uniqueCategories.length * 2; i++) {
			row[i] = { v: 0, e: 0 };
		}
		row[totalCostIndex] = { v: 0, e: 0 };
		// row[unitCostIndex] = { v: 0, e: 0 };
		row[unitCostIndex] = { v: null, e: 0 };
	} else {
		let category = row[COLUMN_INDEXES.CATEGORY].v || "";
		let categoryIndex = uniqueCategories.indexOf(category);
		let dynamicColumnUnitCostIndex = startCellIndex + (categoryIndex * 2);
		let dynamicColumnTotalCostIndex = startCellIndex + (categoryIndex * 2) + 1;
		let quantityAbolut = row[COLUMN_INDEXES.QUANTITY_ABSOLUT].v;
		let unitCost = row[unitCostIndex].v;
		let totalCost = quantityAbolut * unitCost;

		row[totalCostIndex] = { v: totalCost, e: 0 };

		if(uniqueCategories.length) {
			row[dynamicColumnTotalCostIndex] = { v: totalCost, e: 0 };
			row[dynamicColumnUnitCostIndex].v = row[unitCostIndex].v;
		}

		updateParents(rows, rowIndex, totalCost, totalCostIndex, unitCostIndex, dynamicColumnTotalCostIndex, dynamicColumnUnitCostIndex, uniqueCategories);

	}
}

// uodate parents category total/unit cost and overall total/unit cost
function updateParents(rows, rowIndex, totalCost, totalCostIndex, unitCostIndex, dynamicColumnTotalCostIndex, dynamicColumnUnitCostIndex, uniqueCategories) {
	let row = rows[rowIndex];
	let parentRowIndex = row[COLUMN_INDEXES.PARENT_INDEX];
	if(parentRowIndex === -1) return;
	let parentRow = rows[parentRowIndex];
	let parentTotalCost = rows[parentRowIndex][totalCostIndex].v;
	let parentCategoryTotalCost = rows[parentRowIndex][dynamicColumnTotalCostIndex].v;

	if(uniqueCategories.length) {
		parentRow[dynamicColumnTotalCostIndex] = { v: parentCategoryTotalCost + totalCost, e: 0 };
	}
	// parentRow[dynamicColumnUnitCostIndex] = { v: parentRow[dynamicColumnTotalCostIndex].v / parentRow[COLUMN_INDEXES.QUANTITY_ABSOLUT].v, e: 0 };
	parentRow[totalCostIndex] = { v: parentTotalCost + totalCost, e: 0 };
	// parentRow[unitCostIndex] = { v: parentRow[totalCostIndex].v / parentRow[COLUMN_INDEXES.QUANTITY_ABSOLUT].v, e: 0 };F
	updateParents(rows, parentRowIndex, totalCost, totalCostIndex, unitCostIndex, dynamicColumnTotalCostIndex, dynamicColumnUnitCostIndex, uniqueCategories);
}

function calculateQuantity(rows, rowIndex, lastUpdateData) {
	let row = rows[rowIndex];
	if (row[COLUMN_INDEXES.PARENT_INDEX] === -1) {
		row[COLUMN_INDEXES.QUANTITY] = { v: null, e: 0 };

		return;
	}

	if (lastUpdateData.rowIndex === rowIndex && lastUpdateData.cellIndex === Number(COLUMN_INDEXES.QUANTITY_ABSOLUT)) {
		row[COLUMN_INDEXES.QUANTITY] = { v: null, e: 0 };
	}
}

function calculateQuantityAbsolut(rows, rowIndex, lastUpdateData) {
	let row = rows[rowIndex];
	let res;
	if (row[COLUMN_INDEXES.PARENT_INDEX] === -1) {
		if(row[COLUMN_INDEXES.QUANTITY_ABSOLUT].v === null) {
			row[COLUMN_INDEXES.QUANTITY_ABSOLUT] = { v: 1, e: 0 };
		}

		return;
	}

	if (!row[COLUMN_INDEXES.QUANTITY_ABSOLUT].e || (lastUpdateData.rowIndex === rowIndex && lastUpdateData.cellIndex === Number(COLUMN_INDEXES.QUANTITY))) {
		let selfQuantity = row[COLUMN_INDEXES.QUANTITY].v;
		let parentQuantityAbsolute = rows[row[COLUMN_INDEXES.PARENT_INDEX]][COLUMN_INDEXES.QUANTITY_ABSOLUT].v;
		res = selfQuantity * parentQuantityAbsolute;

		row[COLUMN_INDEXES.QUANTITY_ABSOLUT] = { v: res || 1, e: 0 };
	}
}

export function calculatePositionNumber(rows, rowIndex) {
	let row = rows[rowIndex];
	let res = "";
	if (rowIndex === 0) {
		res = "1";
	} else if (row[COLUMN_INDEXES.PARENT_INDEX] === rows[rowIndex - 1][COLUMN_INDEXES.PARENT_INDEX]) { // check if siblings
		let prevPosition = rows[rowIndex - 1][COLUMN_INDEXES.POSITION].v;
		let fragments = prevPosition.split(".");
		fragments[fragments.length - 1] = Number(fragments[fragments.length - 1]) + 1;
		res = fragments.join(".");
	} else if (row[COLUMN_INDEXES.PARENT_INDEX] === rowIndex - 1) { // check if first child
		let parentPosition = rows[rowIndex - 1][COLUMN_INDEXES.POSITION].v;
		res = parentPosition + ".1";
	} else { // is just a child
		let prevSiblingIndex = rowIndex - 1;
		while (rows[prevSiblingIndex][COLUMN_INDEXES.PARENT_INDEX] !== row[COLUMN_INDEXES.PARENT_INDEX]) {
			prevSiblingIndex--;
		}
		let prevPosition = rows[prevSiblingIndex][COLUMN_INDEXES.POSITION].v;
		let fragments = prevPosition.split(".");
		fragments[fragments.length - 1] = Number(fragments[fragments.length - 1]) + 1;
		res = fragments.join(".");
	}

	row[COLUMN_INDEXES.POSITION] = { v: res, e: 0 };
}

