import { useEffect, useRef, useState } from "react";
import * as Styled from "./styled";
import { useDispatch, useSelector } from "react-redux";
import { changeColumnWidth, selectTableData } from "../../tablesSlice";

export default function ColumnResize({ tableId, onClose, cellIndex }) {
	const tableData = useSelector(state => selectTableData(state, tableId));

	const [newWidth, setNewWidth] = useState(tableData.columns[cellIndex].width);
	const inputRef = useRef(null);
	const dispatch = useDispatch();

	function changeWidthHandler() {
		if (!newWidth) {
			onClose();

			return;
		}

		if (newWidth > 10) {
			dispatch(changeColumnWidth({ newWidth, cellIndex, tableId }));
		} else {
			alert("Insert More Than 10");

			return;
		}
		onClose();
	}

	function newWidthHandler(e) {
		if(!Number(e.target.value) && e.target.value.length) return;
		setNewWidth(Number(e.target.value) || "");
	}

	useEffect(() => {
		inputRef.current.focus();
	}, []);

	return (
		<Styled.Wrapper>
			<input
				ref={inputRef}
				type="text"
				value={newWidth}
				onChange={(e) => newWidthHandler(e)}
			/>
			<button onClick={changeWidthHandler}>OK</button>
		</Styled.Wrapper>
	);
}
