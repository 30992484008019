import React, { memo } from "react";
import * as Styled from "./styled";

function MenuButton({ label, showLabel = false, onClick, children }) {

	return (
		<Styled.Wrapper showLabel={showLabel} label={label} onClick={onClick}>
			{children}
		</Styled.Wrapper>
	);
}

export default memo(MenuButton);
