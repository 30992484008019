import styled from "styled-components";

export const ListItem = styled.li`
	display: ${({ show }) => (show ? "flex" : "none")};
	width: 350px;
	box-sizing: border-box;
	align-items: center;
	gap: 5px;
	cursor: pointer;
	font-weight: 500;
	background-color: ${({ selected, theme }) =>
		selected ? theme.colors.active : "initial"};
	border-bottom: ${({ theme }) => `1px solid ${theme.colors.stroke}`};
	padding: ${({ theme }) => `${theme.spacing.xs} ${theme.spacing.m}`};

	&:hover {
		background-color: ${({ theme }) => theme.colors.active};
	}

	span {
		white-space: nowrap;
		display: block;
		vertical-align: middle;
	}

	svg {
		margin-left: ${({ padding }) => padding && padding + "px"};
		visibility: ${({ isParent }) => (isParent ? "visible" : "hidden")};
		cursor: pointer;
		color: ${({ theme }) => theme.colors.textPrimary};
		opacity: 0.8;
	}
`;
