import styled from "styled-components";

export const Wrapper = styled.li`
	display: flex;
	gap: 10px;

	cursor: pointer;
	:hover {
		background-color: #efefef;
	}
	width: 100%;
	padding: 5px;
`;
