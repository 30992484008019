import React, { useEffect } from "react";
import * as Styled from "./styled";
import { addDocumentListeners, removeDocumentListeners } from "./listeners";
import { useSelector } from "react-redux";
import { selectPages } from "../features/pages/pagesSlice";
import { routes } from "../features/pages/routes";
import Loader from "../features/loader/Loader";
import { showLoaderEl } from "../features/loader/loaderSlice";

function App() {
	const { currentPage } = useSelector(selectPages);
	const loaderVisibility = useSelector(showLoaderEl);

	useEffect(function onMount() {
		addDocumentListeners();

		return () => removeDocumentListeners();
	}, []);

	return (
		<Styled.Wrapper className="App" >
			{routes[currentPage]}
			{ loaderVisibility && <Loader />}
		</Styled.Wrapper>
	);
}

export default App;
