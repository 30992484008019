import styled from "styled-components";

export const Cell = styled.div`
    display: inline-block;
    width: ${({ width }) => width+"px"};
    height: 30px;
    padding: 5px;
    border-right: 1px solid silver;
    overflow: hidden;
    text-wrap: nowrap;
    font-weight: ${({ isHeader }) => isHeader ? 600 : "initial"};
    text-align: ${({ isHeader }) => isHeader ? "center" : "initial"};
`;
