import React, { useState } from "react";
import * as Styled from "./styled";
import { useDispatch, useSelector } from "react-redux";
import { changeColumnVisibility, resetColumnVisibility, selectTableData, updateUniqueCategories, visibleAllColumns } from "../tables/tablesSlice";
import { ReactComponent as CheckAll } from "../../icons/checkAll.svg";
import { ReactComponent as Arrow } from "../../icons/arrow-left.svg";
import { getUniqueCategories } from "../tables/helpers";

export default function ColumnsPopup({ children, tableId }) {
	const tableData = useSelector((state) => selectTableData(state, tableId));
	const { columns, rows, uniqueCategories } = tableData;
	const dispatch = useDispatch();
	const [showPopup, setShowPopup] = useState(false);

	function openPopup(e) {
		const lastUniqueCategories = getUniqueCategories(rows);
		let result = false;
		if(lastUniqueCategories.length !== uniqueCategories.length) {
			result = false;
		} else {
			const elements = new Set([...lastUniqueCategories, ...uniqueCategories]);
			for (const el of elements) {
				const length1 = lastUniqueCategories.filter(e => e === el).length;
				const length2 = uniqueCategories.filter(e => e === el).length;
				if (length1 !== length2) {
					result = false;
				}
				result = true;
			}
		}

		if(!result && !showPopup) {
			dispatch(updateUniqueCategories({ tableId }));
			// !showPopup && dispatch(resetColumnVisibility({ tableId }));
		}
		setShowPopup(!showPopup);
	}

	function checkColumn(event ,index) {
		dispatch(changeColumnVisibility({ index, tableId }));
		event.stopPropagation();
	}

	function selectAll(e) {
		dispatch(visibleAllColumns({ tableId }));
		e.stopPropagation();
	}

	return (
		<Styled.Wrapper onClick={openPopup}>
			{children}
			{
				showPopup &&
				<Styled.Popup>
					{columns.map((column, index) => {
						return <Styled.ListItem
							onClick={(event) => checkColumn(event , index)}
							key={column.title}
						>
							<Styled.Checkbox selected={column.show}>{column.show ? <CheckAll/> : <></>}</Styled.Checkbox>
							{column.title}
						</Styled.ListItem>;
					})}
					<div onClick={selectAll}>Select All</div>
				</Styled.Popup>
			}
		</Styled.Wrapper>
	);
}
