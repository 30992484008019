import React from "react";
import * as Styled from "./styled";

export default function ListItem({ properties, clickHandler }) {

	return (
		<Styled.Wrapper onClick={clickHandler}>
			<span>{properties.position}</span>
			<span>{properties.itemName}</span>
		</Styled.Wrapper>
	);
}
