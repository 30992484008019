import styled from "styled-components";

export const Wrapper = styled.div`
	position: absolute;
	display: flex;
	gap: 2px;
	z-index: 11;
	top: 24px;
	border: 1px solid lightgray;
	padding: 2px;
	border-radius: 3px;
	background-color: #fff;
	input {
		width: 55px;
		border: none;
		outline: none;
	}
	button {
		border: none;
		border-radius: 3px;
		cursor: pointer;
		background-color: #fff;
		:hover {
			background-color: #F2F3F3;
		}
	}
`;

