import { createSlice } from "@reduxjs/toolkit";

const initialState = window.initialState = {
	selectedRowIndex: -1,
	expandedRowIds: {},
};

const projectTreeSlice = createSlice({
	name: "projectTree",
	initialState: initialState,
	reducers: {
		toggleTreeExpandedStatus(state, action) {
			const { id } = action.payload;
			if(state.expandedRowIds[id] === 1) {
				state.expandedRowIds[id] = 0;
			} else {
				state.expandedRowIds[id] = 1;
			}
		},
		setSelectedRowIndex(state, action) {
			state.selectedRowIndex = action.payload.rowIndex;
		},
	},
});

export function selectProjectTreeData(state) {
	return state.projectTree;
}

export function selectSelectedRowIndex(state) {
	return state.projectTree.selectedRowIndex;
}

export const { toggleTreeExpandedStatus, setSelectedRowIndex } = projectTreeSlice.actions;

export default projectTreeSlice.reducer;
