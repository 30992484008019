import styled from "styled-components";

export const Wrapper = styled.div`
	position: relative;
	width: fit-content;
	padding: ${({ theme }) => theme.spacing.xs};
	cursor: pointer;
	background-color: #fff;

	/* &:hover:after {
		content:  ${({ showLabel }) => !showLabel ? "attr(label)" : ""};
		position: absolute;
		top: -50%;
		left: 100%;
		width: max-content;
		padding: 0.143rem;
		border-radius: 3px;
		background-color: #fff;
		z-index: ${({ theme }) => theme.zIndexes.z30};
		box-shadow: 3px 3px 10px -4px rgba(0,0,0,1);
		-webkit-box-shadow: 3px 3px 10px -4px rgba(0,0,0,1);
		-moz-box-shadow: 3px 3px 10px -4px rgba(0,0,0,1);
	} */

	svg {
		width: 28px;
		vertical-align: middle;
		color: ${({ theme }) => theme.colors.textPrimary};
		cursor: pointer;
		:hover {
			scale: 1.1;
		}
	}

	span {
		display: ${({ showLabel }) => showLabel ? "initial" : "none"};
		vertical-align: middle;
		margin-left: 0.5rem;
		font-size: 1.2rem;
		font-weight: 500;
	}
`;
