import styled from "styled-components";

export const Item = styled.li`
    display: flex;
    gap: ${({ theme }) => theme.spacing.m};
    padding: ${({ theme }) => `${theme.spacing.s} ${theme.spacing.m}`};
    border-radius: ${({ theme }) => theme.radius.xs};
    color: ${({ theme }) => theme.colors.textBlur};
    align-items: center;
    list-style: none;

    svg {
        flex-shrink: 0;
    }

    svg path {
        stroke: ${({ theme }) => theme.colors.textBlur};
    }

    cursor: pointer;
`;
