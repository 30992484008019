import styled from "styled-components";

export const Wrapper = styled.div`
    position: relative;
`;

export const Popup = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: ${({ theme }) => theme.zIndexes.z50};
    top: 35px;
    width: 200%;
    background-color: #414a4c;
    gap: 2px;
    user-select: none;
    border-radius: ${({ theme }) => theme.radius.xs};
    overflow: hidden;

    >div {
        background-color: #353839;
        color: white;
        padding: 1rem;
        cursor: pointer;
    }

    >div:hover {
       background-color: #232b2b;
    }

    >svg {
        transform: rotate(90deg);
        padding: ${({ theme }) => theme.spacing.xs};
    }
`;

export const ListItem = styled.div`
    display: flex;
    gap: ${({ theme }) => theme.spacing.s};
`;

export const Checkbox = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${({ theme }) => theme.spacing.m};
    height: ${({ theme }) => theme.spacing.m};
    border-radius: 2px;
    border: 1px solid gray;
    background-color: ${({ selected }) => selected ? "#0266EF" : "#fff"};
    cursor: pointer;
`;
