import React, { useState } from "react";
import * as Styled from "./styled";
import { ReactComponent as ProjectTreeIcon } from "../../icons/project-tree.svg";
import { ReactComponent as PropertiesIcon } from "../../icons/properties-icon.svg";
import { ReactComponent as Search } from "../../icons/search.svg";
import { routes } from "./routes";
import { DETAILS } from "./constants";

export default function DetailsPanel({ tableId, panelsCount, tableRef }) {
	const [currentpanel, setCurrentPanel] = useState(DETAILS.PROJECTTREE);
	let Comp = routes[currentpanel];

	return (
		<Styled.Wrapper panelsCount={ panelsCount }>
			<Styled.IconsWrapper >
				<Styled.Icon
					active={currentpanel === DETAILS.PROJECTTREE}
					onClick={() => setCurrentPanel(DETAILS.PROJECTTREE)}
				>
					<ProjectTreeIcon/>
				</Styled.Icon>
				<Styled.Icon
					active={currentpanel === DETAILS.ROWPROPS}
					onClick={() => setCurrentPanel(DETAILS.ROWPROPS)}
				>
					<PropertiesIcon/>
				</Styled.Icon>
				<Search
					active={currentpanel === DETAILS.SEARCH}
					onClick={() => setCurrentPanel(DETAILS.SEARCH)}
				/>
			</Styled.IconsWrapper>
			<Styled.Main>
				<Comp tableId={tableId} tableRef={tableRef}/>
			</Styled.Main>
		</Styled.Wrapper>
	);
}
