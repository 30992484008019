import React, { useEffect, useState } from "react";
import * as Styled from "./styled";
import { ReactComponent as UpAndDown } from "../../../icons/up-and-down.svg";
import { useDispatch, useSelector } from "react-redux";
import {
	createProject,
	fetchAllProjects,
	selectActiveProjectId,
	selectAllProjects,
	setActiveProjectId,
} from "../../projects/projectsSlice";
import { setPage } from "../pagesSlice";
import { PAGES } from "../constants";
import Popup from "../../../common/ui/Popup";
import * as API from "../../../app/api";
import { defaultColumns } from "../../tables/Columns";
import { DYNAMIC_COLUMNS_OFFSET, TABLE_TYPE } from "../../tables/constants";
import { updateColumnsWithUniqueCategories } from "../../tables/helpers";
import { changeLoaderVisibility } from "../../loader/loaderSlice";

export default function Dashboard() {
	const projects = useSelector(selectAllProjects);
	const dispatch = useDispatch();
	const [creating, setCreating] = useState(false);
	const [projectName, setProjectName] = useState("");
	const [tableId, setTableId] = useState(localStorage.getItem("table_id"));

	function chooseProjectHandler(id) {
		dispatch(setActiveProjectId({ id }));
		localStorage.setItem("table_id", id);
		goToHomePage();
	}

	function createNewProject() {
		let row = [1, -1, ...defaultColumns.map((column) => {
			if (column.type === "text") {
				return { v: "", e: 0 };
			} else {
				return { v: null, e: 0 };
			}
		})];
		let uniqueCategories = [];

		let columns = updateColumnsWithUniqueCategories(defaultColumns, uniqueCategories);

		API.createNewProject({
			meta: {
				name: projectName,
			},
			estimates: {
				rows: [row],
				rowProps: {
					1: {
						expanded: true,
						selected: false,
						height: 20,
					},
				},
				columns: columns,
				uniqueCategories: uniqueCategories,
				filteredRowIndex: -1,
				selectedCell: {
					cellIndex: null,
					rowIndex: 0,
				},
				nextId: 2,
				type: TABLE_TYPE.ESTIMATES,
			},
			expenses: {
				templates: [
					{
						name: "Default template",
						rows: [
							{
								description: "",
								percentage: 0,
								fixedValue: 0,
								from: [],
								filter: [],
							},
						],
					},
				],
				bindings: {},
			},
		}).then((projectId) => {
			dispatch(createProject({ projectId, meta: { name: projectName } }));
			dispatch(setActiveProjectId({ id: projectId }));
			setCreating(false);
			dispatch(setPage({ page: PAGES.HOME }));
		});
	}

	function goToHomePage() {
		dispatch(setPage({ page: PAGES.HOME }));
	}

	function logOut() {
		dispatch(changeLoaderVisibility({ show: true }));
		localStorage.removeItem("accessToken");
		localStorage.removeItem("table_id");
		window.location.reload();
	}

	useEffect(() => {
		dispatch(fetchAllProjects());
		dispatch(changeLoaderVisibility({ show: false }));
	}, [dispatch]);

	useEffect(() => {
		if (tableId) {
			chooseProjectHandler(localStorage.getItem("table_id"));
			localStorage.removeItem("table-id");
		}
	}, [tableId]);

	return (
		<Styled.Wrapper>
			<Styled.ProjectsWrapper>
				<Styled.Header>
					<div>
						<h3>Project</h3>
						<span>Opened by you</span>
					</div>
					<button onClick={() => setCreating(true)}>+ Add new Project</button>
				</Styled.Header>
				<Styled.List>
					<Styled.ListHeader>
						<div>
							<h4>Project Name</h4>
							<UpAndDown />
						</div>
						<div>
							<span>Date Modified</span>
							<UpAndDown />
						</div>
					</Styled.ListHeader>
					{projects.all.map((project) => {
						return (
							<Styled.ListItem
								key={project.id}
								onClick={() => chooseProjectHandler(project.id)}
							>
								{project.meta.name}
							</Styled.ListItem>
						);
					})}
				</Styled.List>
			</Styled.ProjectsWrapper>
			<Styled.ProjectsWrapper>
				<Styled.Header>
					<div>
						<h3>Library</h3>
						<span>Shared with you</span>
					</div>
				</Styled.Header>
			</Styled.ProjectsWrapper>
			<button onClick={logOut}>Logout</button>
			{creating &&
				<Popup onClose={() => setCreating(false)}>
					<div className="popup">
						<input type="text" placeholder="Project name" onChange={(e) => setProjectName(e.target.value)} />
						<button onClick={createNewProject}>Create</button>
					</div>
				</Popup>}
		</Styled.Wrapper>
	);
}
