import styled from "styled-components";

export const Wrapper = styled.li`
	display: flex;
  	align-items: center;
	background-color: transparent;
	color: white;
	cursor: pointer;
	padding: 3px 7px;
	border-radius: 3px;
	&:hover {
		background-color: #232b2b;
	}

	input {
		padding: 0;
		margin: 0;
		cursor: pointer;
	}
`;

