import styled from "styled-components";

export const Wrapper = styled.nav`
    display: flex;
    flex-direction: column;
    color: ${({ theme }) => theme.colors.textBlur};
    background-color: #f7f7f7;
    border-left: ${({ theme }) => `1px solid ${theme.colors.stroke}`};
    width: ${({ theme }) => theme.width.detailsWidth};
    height: 100%;
    border-top: 1px solid #fff;
`;

export const IconsWrapper = styled.div`
    display: flex;
    gap: ${({ theme }) => theme.spacing.m};
    padding: ${({ theme }) => theme.spacing.xl};
    position: fixed;
    background-color: #f7f7f7;
    z-index: ${({ theme }) => theme.zIndexes.z20};
    width: 100%;

    svg {
        &:hover path{
            stroke: black;
        }
    }
`;

export const Icon = styled.div`
    svg {
        path {
            stroke: ${({ active }) => active ? "black" : "silver"}
        }
    }
`;

export const Main = styled.div`
    margin-top: 56px;
    height: calc(100% - 56px);
    overflow: hidden scroll;
`;
