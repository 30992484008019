import styled from "styled-components";

export const Row = styled.div`
	position: relative;
    display: flex;
	align-items: center;
	padding: 10px 0;
	background-color: ${({ isHeader, theme, edited }) => theme.colors.header};
	width: ${({ width }) => width}px;
	font-weight: bold;
	color: #141719;
	height: ${({ height }) => height};
`;

export const Checkbox = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 12px;
    height: 12px;
    border-radius: 2px;
    border: 1px solid gray;
    background-color: ${({ selected }) => selected ? "#0266EF" : "#fff"};
`;

export const Cell = styled.div`
    box-sizing: border-box;
	width: ${({ width, isFirstColumn }) => isFirstColumn ? "auto" : width + "px"};
	overflow: hidden;
	background-color: ${({ isHeader, theme, edited }) => theme.colors.header};
	transition: all ease-in-out 200ms;
	flex-shrink: 0;
	text-align: ${({ align }) => align};
	color: #141719;
	padding-right: 20px;
`;

export const Title = styled.div`
	display: flex;
	gap: 5px;
	align-items: center;
	>div:first-child {
		overflow: hidden;
	}
`;

export const Icon = styled.div`
	cursor: pointer;
	svg {
		color: silver;
		&:hover {
			color: #3498db;
		}
	}

`;
