import store from "../../app/store";
import { getActiveContentId } from "../panels/helpers";
import { CELL_TEXT_ALIGN, COLUMN_INDEXES, DYNAMIC_COLUMNS_OFFSET, FORMULAS } from "./constants";

export function* generateNestingLevels() {
	let paddings = [];
	let row;
	while (true) {
		let padding = 0;
		let parentIndex = row ? row[COLUMN_INDEXES.PARENT_INDEX] : -1;

		if (parentIndex > -1) {
			padding = paddings[parentIndex] + 1;
		}

		paddings.push(padding);
		row = yield padding;
	}
}

export function branchShouldBeVisible(rows, expandedRowIds, row) {
	let parent = rows[row[COLUMN_INDEXES.PARENT_INDEX]];
	while (parent) {
		if (expandedRowIds[parent[COLUMN_INDEXES.ID]] === 0 || !expandedRowIds[parent[COLUMN_INDEXES.ID]]) {
			return false;
		}
		parent = rows[parent[COLUMN_INDEXES.PARENT_INDEX]];
	}

	return true;
}

export function findNextSiblingsIndex(rows, rowIndex) {
	let siblingsIndex = rowIndex + 1;
	for (let i = rowIndex + 1; i < rows.length; i++) {
		if (rows[i][COLUMN_INDEXES.PARENT_INDEX] <= rows[rowIndex][COLUMN_INDEXES.PARENT_INDEX]) {
			siblingsIndex = i;
			break;
		}
		siblingsIndex = i + 1;
	}

	return siblingsIndex;
}

export function increaseParentIndexes(rows, minParentIndex, start = 0, increaseBy = 1) {
	for (let i = start; i < rows.length; i++) {
		if (rows[i][COLUMN_INDEXES.PARENT_INDEX] > minParentIndex) {
			rows[i][COLUMN_INDEXES.PARENT_INDEX] += increaseBy;
		}
	}
}

export function calculateRowWidth(columns) {
	let fullWidth = 0;
	if (columns) {
		columns.forEach(column => fullWidth += column.width);
	}

	return fullWidth;
}

export function registerIf(condition) {
	let cb;

	return function(handler, ...args) {
		if (!condition) return undefined;
		if (!cb) {
			cb = (...eventArgs) => handler(...args, ...eventArgs);
		}

		return cb;
	};
}

export function roundTo(number, digits = 0) {
	return Math.round(number * 10 ** digits) / 10 ** digits;
}

export function parseValue(value, type) {
	switch (type) {
	case "number":
		return Number(value);
	default:
		return String(value);
	}
}

export function checkIsNumber(value) {
	return RegExp(/^[0-9]+\.?([0-9]+)?$/).test(value);
}

export function parseNumber(value) {
	if (value[value.length - 1] === ".") {
		return Number(value.slice(0, -1));
	}

	return value;
}

export function getLastChildIndex(index, rows) {
	let lastIndex = null;
	for (let i = index + 1; i < rows.length; i++) {
		if (rows[i][COLUMN_INDEXES.PARENT_INDEX] >= index) {
			lastIndex = i;
		} else {
			break;
		}
	}

	return lastIndex;
}

export function getLastVisibleChildIndex(index, rows, rowProps) {
	if (!rowProps[rows[index][COLUMN_INDEXES.ID]].expanded) {
		return null;
	}

	let lastIndex = null;

	for (let i = index + 1; i < rows.length; i++) {
		if (rows[i][COLUMN_INDEXES.PARENT_INDEX] <= rows[index][COLUMN_INDEXES.PARENT_INDEX]) {
			break;
		}
		lastIndex = i;
		if (!rowProps[rows[i][COLUMN_INDEXES.ID]].expanded) {
			let currentIndex = i;
			for (let j = i + 1; j < rows.length; j++) {
				if (rows[j][COLUMN_INDEXES.PARENT_INDEX] <= rows[currentIndex][COLUMN_INDEXES.PARENT_INDEX]) {
					break;
				}
				i++;
			}
		}
	}

	return lastIndex;
}

export function isParent(rowIndex, rows) {
	if(!rows) {
		let tableId = getActiveContentId();
		rows = store.getState().tables[tableId].rows;
	}
	if (rowIndex === rows.length - 1) {
		return false;
	}

	return rowIndex === rows[rowIndex + 1][COLUMN_INDEXES.PARENT_INDEX];
}

export function childrenCount(rows, rowIndex) {
	let count = 0;
	for (let i = rowIndex + 1; i < rows.length; i++) {
		if (rows[i][COLUMN_INDEXES.PARENT_INDEX] <= rows[rowIndex][COLUMN_INDEXES.PARENT_INDEX]) {
			break;
		}
		count++;
	}

	return count;
}

export function createSingleRowProps(expanded = true, selected = false, height = 20) {
	return {
		expanded,
		selected,
		height,
	};
}

export function generateInitialRowProps(number, expanded = true, selected = false) {
	let res = {};

	for (let i = 1; i <= number; i++) {
		res[i] = createSingleRowProps(expanded, selected);
	}

	return res;
}

export function getUniqueCategories(rows) {
	const category = new Set();
	let hasOther = false;
	for (let i = 0; i < rows.length; i++) {
		if(isParent(i, rows)) {
			continue;
		}
		if (rows[i][COLUMN_INDEXES.CATEGORY].v === "" || rows[i][COLUMN_INDEXES.CATEGORY].v == null) {
			hasOther = true;
		} else {
			category.add(rows[i][COLUMN_INDEXES.CATEGORY].v);
		}
	}
	if (hasOther) {
		category.add("");
	}

	return [...category];
}

export function updateColumnsWithUniqueCategories(columns, uniqueCategories) {
	let dynamicColumns = [];
	let lastCellIndex = columns[DYNAMIC_COLUMNS_OFFSET - 1].cellIndex;
	uniqueCategories.forEach((category, index) => {
		const columnUnitCost = { title: `${category === "" ? "Other" : category} unit Cost`, type: "number", formula: null, show: false, editable: true, width: 100, align: CELL_TEXT_ALIGN.END };
		const columnTotalCost = { title: `${category === "" ? "Other" : category} total Cost`, type: "number", formula: FORMULAS.CATEGORIES_TOTAL_COST, show: false, editable: false, width: 100, align: CELL_TEXT_ALIGN.END };
		dynamicColumns.push(columnUnitCost);
		dynamicColumns.push(columnTotalCost);
	});

	columns.splice(DYNAMIC_COLUMNS_OFFSET, 0, ...dynamicColumns);
	for(let i = DYNAMIC_COLUMNS_OFFSET; i < columns.length; i++) {
		columns[i].cellIndex = ++lastCellIndex;
	}

	return columns;
}

export function getChildren(rows, rowIndex) {
	let res = [];
	let childIndex = rowIndex + 1;
	while (rows[childIndex] && rows[childIndex][COLUMN_INDEXES.PARENT_INDEX] >= childIndex) {
		res.push(rows[childIndex]);
		childIndex++;
	}

	return res;
}

export function* generateNestedRows(rows, rowIndex, resourcesOnly = false) {
	let childIndex = rowIndex + 1;
	if (childIndex >= rows.length) {
		return;
	}
	while (rows[childIndex] && rows[childIndex][COLUMN_INDEXES.PARENT_INDEX] >= rowIndex) {
		if (!resourcesOnly || !isParent(childIndex)) {
			yield { index: childIndex, row: rows[childIndex] };
		}
		childIndex++;
	}
}

export function getTotalCostColumnIndex(tableId, state) {
	let currentState = null;
	if(!state) {
		currentState = store.getState().tables[tableId];
	} else {
		currentState = state.tables[tableId];
	}

	return DYNAMIC_COLUMNS_OFFSET + currentState.uniqueCategories.length * 2 + 1;
}

export function getTotalCostCellIndex(tableId, state) {
	let currentState = null;
	if(!state) {
		currentState = store.getState().tables[tableId];
	} else {
		currentState = state.tables[tableId];
	}

	const columnIndex = getTotalCostColumnIndex(tableId);

	return currentState.columns[columnIndex].cellIndex;
}

export function getUnitCostIndex(tableId, state) {
	let currentState = null;
	if(!state) {
		currentState = store.getState().tables[tableId];
	} else {
		currentState = state[tableId];
	}

	return DYNAMIC_COLUMNS_OFFSET + currentState.uniqueCategories.length * 2;
}

export function getDynamicUnitCostIndexes(tableId, state) {
	const startIndex = DYNAMIC_COLUMNS_OFFSET;
	const lastIndex = getUnitCostIndex(tableId, state);
	const indexes = [];
	for(let i = startIndex; i < lastIndex; i += 2) {
		indexes.push(i);
	}

	return indexes;
}

export function getCategoryIndex(tableId, state, category) {
	let currentState = null;
	if(!state) {
		currentState = store.getState().tables[tableId];
	} else {
		currentState = state[tableId];
	}

	const columns = currentState.columns;
	const startCellIndex = columns[DYNAMIC_COLUMNS_OFFSET - 1].cellIndex + 1;

	return startCellIndex + (currentState.uniqueCategories.indexOf(category) * 2);
}

export function calculateNextTableId() {
	const tableIds = Object.keys(store.getState().tables);

	return Math.max(...tableIds) + 1;
}

export function getAncestorsCount(row, tableId, filteredRowIndex) {
	const rows = store.getState().tables[tableId].rows;
	let firstRowIndex = filteredRowIndex != null && filteredRowIndex !== -1 ?
		rows[filteredRowIndex][COLUMN_INDEXES.PARENT_INDEX] : -1;
	let parentIndex = row[COLUMN_INDEXES.PARENT_INDEX];
	let res = 0;
	while(parentIndex > firstRowIndex) {
		res++;
		parentIndex = rows[parentIndex][COLUMN_INDEXES.PARENT_INDEX];
	}

	return res;

}

export function* generateDescendantIndexes(rowIndex, rows) {
	for(let i = rowIndex + 1; i < rows.length; i++) {
		if(rows[i][1] < rowIndex) {
			break;
		}
		yield i;
	}
}

export function getSelectedValue(rowIndex, rows, rowProps) {
	if(rowIndex !== -1) {
		let rowId = rows[rowIndex][COLUMN_INDEXES.ID];
		if(!rowProps[rowId].selected) {
			return 0;
		}
	}

	let iterator = generateDescendantIndexes(rowIndex, rows);
	for(let childIndex of iterator) {
		let childId = rows[childIndex][COLUMN_INDEXES.ID];

		if(!rowProps[childId].selected) {
			return rowIndex === -1 ? 0 : 2;
		}
	}

	return 1;
}

