import { useEffect, useMemo, useRef, useState } from "react";
import * as Styled from "./styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { assignTemplate, changeTemlateName, createTemplate, removeTemplate, selectTemplateBindings } from "../../limitExpensesSlice";

export default function SelectTemplate({ templates, templateIndex, selectedRowId }) {
	const [showOptions, setShowOptions] = useState(false);
	const [top, setTop] = useState(0);
	const [showTemplateNameInput, setShowTemplateNameInput] = useState(false);
	const [newTemplateName, setNewTemplateName] = useState("");
	const [isEditing, setIsEditing] = useState(false);
	const bindings = useSelector((state) => selectTemplateBindings(state));

	const optionsRef = useRef();
	const templateNameInput = useRef();
	const dispatch = useDispatch();

	function changeTemplateHandler(templateName, templateIndex) {
		// setShowOptions(false);
		dispatch(assignTemplate({ selectedRowId, templateIndex }));
		// setCurrentTemplate(templateName);
	}

	function toggleShowOptions() {
		setShowOptions(!showOptions);
	}

	function enterTempleteName(e) {
		setNewTemplateName(e.target.value);
	}

	function renameExistingTemplate() {
		if (newTemplateName.length === 0) {
			setShowTemplateNameInput(false);

			return;
		}
		if(templates.map(temp => temp.name).includes(newTemplateName) && templates[templateIndex].name !== newTemplateName) {
			alert("This Template Already Exists!");

			return;
		}
		setIsEditing(false);
		setNewTemplateName("");
		setShowTemplateNameInput(false);
		dispatch(changeTemlateName({ templateIndex, changedName: newTemplateName }));
	}

	function createNewTemplate() {
		if (newTemplateName.length === 0) {
			setShowTemplateNameInput(false);

			return;
		}

		if(templates.map(temp => temp.name).includes(newTemplateName)) {
			alert("This Template Already Exists!");

			return;
		}
		setShowTemplateNameInput(false);
		dispatch(createTemplate({ name: newTemplateName }));
		dispatch(assignTemplate({ selectedRowId, templateIndex: templates.length }));
	}

	function editTemplateName(name) {
		setShowTemplateNameInput(true);
		setNewTemplateName(name);
		setIsEditing(true);
	}

	function removeSelectedTemplate() {
		if (templateIndex === 0) {
			alert("You can't delete default template");

			return;
		}
		dispatch(removeTemplate({ templateIndex }));
	}

	useEffect(() => {
		if(optionsRef.current) {
			setTop(-optionsRef.current.offsetHeight);
		}
	}, [templates, showOptions]);

	function onChangeOrAddtemplate() {
		if(isEditing) {
			renameExistingTemplate();
		} else {
			createNewTemplate();
		}
	}

	return (
		<Styled.Wrapper>
			<Styled.Select>
				<Styled.Current>{templates[templateIndex].name}</Styled.Current>
				<span onClick={toggleShowOptions}> &#x276E; </span>
			</Styled.Select>
			{showOptions &&
				<Styled.Options top={top}>
					<div ref={optionsRef}>
						{
							templates.map((template, i) => {
								return (
									<Styled.OptionsItems key={template.name} selected={bindings[selectedRowId] === i}>
										<div onClick={() => changeTemplateHandler(template.name, i)}>{template.name}</div>
										<div>
											<FontAwesomeIcon onClick={() => editTemplateName(template.name)} icon={faEdit} opacity={0.7} />
											<FontAwesomeIcon icon={faTrash} onClick={removeSelectedTemplate} opacity={0.7} />
										</div>
									</Styled.OptionsItems>
								);
							})
						}
					</div>
					{
						showTemplateNameInput ?
							<div className="editing">
								<input
									onChange={enterTempleteName}
									placeholder="enter name"
									value={newTemplateName}
									ref={templateNameInput}
								/>
								<button onClick={onChangeOrAddtemplate}>done</button>
							</div> :
							<div onClick={() => setShowTemplateNameInput(true)}>+ Add New Template</div>
					}
				</Styled.Options>}
		</Styled.Wrapper>
	);
}
