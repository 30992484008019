import styled from "styled-components";

export const Cell = styled.div`
    display: flex;
    padding: 4px 5px;
    width: 80px;
    flex-shrink: 0;
    user-select: none;
`;

export const EmptyDiv = styled.div`
    box-sizing: content-box;
    width: 10px;
    height: 10px;
    padding: 0 5px;
    cursor: pointer;

    svg {
        display: none;
    }

    &:hover {
        svg {
            display: block;
        }
    }
`;

export const Checkbox = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 12px;
    height: 12px;
    border-radius: 2px;
    border: 1px solid gray;
    background-color: ${({ selected }) => selected ? "#0266EF" : "#fff"};
    cursor: pointer;
`;

export const Num = styled.div`
    display: flex;
    margin-left: 10px;
    width: 20px;
`;

