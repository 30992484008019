import styled from "styled-components";

export const Wrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
`;

export const Container = styled.div`
	width: 340px;
	.logo {
		display: flex;
		justify-content: center;
		img {
			margin: 0 auto;
			width: 60px;
		}
	}
	.heading-text {
		color: #141719;
		font-size: 24px;
		font-weight: 750;
		line-height: 28px;
		margin-top: 40px;
		text-align: center;
	}
	.remember-me {
		margin-top: 16px;
		display: flex;
		gap: 10px;
		align-items: center;
		color: #15181a;
		font-size: 12px;
		font-weight: 400;
		line-height: 22px;
		input {
			cursor: pointer;
		}
	}
	.submit {
		margin-top: 32px;
		button {
			width: 100%;
			cursor: pointer;
		}
	}
	.have-account {
		display: flex;
		flex-direction: column;
		margin-top: 26px;
		align-items: center;
		gap: 4px;
		> div {
			display: flex;
			gap: 8px;
			> span {
				font-size: 12px;
				font-weight: 400;
				line-height: 20px;
			}
			> span:first-child {
				color: #6f787b;
			}
			> span:last-child {
				text-decoration: underline;
				cursor: pointer;
				color: #0266ef;
			}
		}
		svg {
			path {
				stroke: #0266ef;
			}
			cursor: pointer;
		}
	}
`;

export const Inputs = styled.div`
	margin-top: 48px;
	display: flex;
	flex-direction: column;
	gap: 12px;
	align-items: center;

	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
	}
`;

export const Input = styled.input`
	all: unset;
	width: 100%;
	border: 1px solid #D1D5D6;
	/* border: ${(props) => (`1px solid ${props.valid ? "#D1D5D6" : "red"}`)}; */
	border-radius: ${({ theme }) => theme.radius.xs};
	padding: ${({ theme }) => `${theme.spacing.s} ${theme.spacing.m}`};
	font-size: ${({ theme }) => theme.fontSize.s};
	box-sizing: border-box;

	&:placeholder {
		color: #a6abad;
	}

	&:focus {
		border: 1px solid #a6abad;
	}
`;

