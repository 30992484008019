import React, { useContext, useCallback, useState, useRef, useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import * as Styled from "./styled";

import { addRow, removeRow, replaceRow, selectRow, selectTableData } from "./tablesSlice";
import { ContextMenuContext } from "../../contexts/contextMenuContext";

import { CONTEXT_MENU_CONSTANTS } from "../../app/constants";
import { ADD_ROW_OPTION, COLUMN_INDEXES, HEADER_ROW_WIDTH, RENDERED_ROWS_COUNT } from "./constants";
import SelectionWidget from "../selection/SelectionWidget";
import HeaderRow from "./HeaderRow";
import { findNextSiblingsIndex, getSelectedValue, isParent } from "./helpers";
import { pasteHandler } from "../../app/actions";

export default function Table({ tableId, panelsCount, showExpenses, setChildRef }) {
	const dispatch = useDispatch();
	const { showContextMenu } = useContext(ContextMenuContext);

	const tableData = useSelector((state) => selectTableData(state, tableId));
	const { rows, columns, rowProps, uniqueCategories, selectedCell, filteredRowIndex } = tableData;

	const [scrollTop, setScrollTop] = useState(0);
	const windowWrapper = useRef();

	const contextMenuHandler = useCallback((e, rowIndex) => {
		showContextMenu(CONTEXT_MENU_CONSTANTS.ROW, e, [
			() => dispatch(addRow({ index: rowIndex, option: ADD_ROW_OPTION.CHILD })),
			() => dispatch(addRow({ index: rowIndex, option: ADD_ROW_OPTION.SIBLING_ABOVE })),
			() => dispatch(addRow({ index: rowIndex, option: ADD_ROW_OPTION.SIBLING_BELOW })),
			() => dispatch(removeRow({ index: rowIndex })),
			() => {
				dispatch(addRow({ index: rowIndex, option: ADD_ROW_OPTION.SIBLING_ABOVE }));
				dispatch(selectRow({ rowIndex, withChildren: false }));
				pasteHandler();
			},
			() => {
				dispatch(addRow({ index: rowIndex, option: ADD_ROW_OPTION.SIBLING_BELOW }));
				dispatch(selectRow({ rowIndex: findNextSiblingsIndex(rows, rowIndex), withChildren: false }));
				pasteHandler();
			},
		]);
	}, [dispatch, showContextMenu, rows]);

	useEffect(function addRowForEmptyTable() {

		if (rows.length === 0) {
			dispatch(replaceRow({ removeIndexes: [0] }));
		}
	}, [rows, dispatch]);

	useEffect(function registerScroll() {
		function handler(e) {
			setScrollTop(lastTop => {
				if (Math.abs(e.target.scrollTop - lastTop) > 19) {
					return e.target.scrollTop;
				}

				return lastTop;
			});
		}

		let wrapper = windowWrapper.current;

		wrapper.addEventListener("scroll", handler);

		return () => wrapper.removeEventListener("scroll", handler);
	}, []);

	useEffect(() => {
		if(windowWrapper.current) {
			setChildRef(windowWrapper.current);
		}
	}, [setChildRef]);

	const visibleRows = useMemo(() => {
		let res = [];
		let startRowIndex = 0;
		let lastRowIndex = rows.length - 1;
		let top = 0;

		if(filteredRowIndex != null && filteredRowIndex > -1) {
			startRowIndex = filteredRowIndex;
			lastRowIndex = startRowIndex + 1;

			while(rows[lastRowIndex] && rows[lastRowIndex][COLUMN_INDEXES.PARENT_INDEX] >= startRowIndex) {
				lastRowIndex++;
			}

			lastRowIndex--;
		}

		for (let rowIndex = startRowIndex; rowIndex <= lastRowIndex; rowIndex++) {
			let row = rows[rowIndex];
			res.push({ rowIndex, row, top });
			top += rowProps[row[COLUMN_INDEXES.ID]].height;
			if (!rowProps[row[COLUMN_INDEXES.ID]].expanded) {
				let childIndex = rowIndex + 1;
				while (childIndex < rows.length && rowIndex <= rows[childIndex][COLUMN_INDEXES.PARENT_INDEX]) {
					childIndex++;
				}
				rowIndex = childIndex - 1;
			}
		}

		return res;
	}, [rows, rowProps, filteredRowIndex]);

	const rowWidth = useMemo(() => columns.reduce((prev, col) => prev + (col.show ? col.width : 0), 0) + 80, [columns]);

	let firstIndex = useMemo(() => {
		let prev = 0;
		for(let i = 0; i < visibleRows.length; i++) {
			if(visibleRows[i].top <= scrollTop) {
				prev = i;
			} else {
				break;
			}
		}

		return Math.max(prev - RENDERED_ROWS_COUNT, 0);
	}, [scrollTop, visibleRows]);
	let lastIndex = firstIndex + RENDERED_ROWS_COUNT * 3;
	const visibleRowsHeight = rowProps[visibleRows[visibleRows.length - 1].row[COLUMN_INDEXES.ID]].height + visibleRows[visibleRows.length - 1].top;

	return (
		<Styled.Wrapper showExpenses={showExpenses}>
			<HeaderRow tableId={tableId} columns={columns} selected={getSelectedValue(-1, rows, rowProps)} width={rowWidth}/>
			<Styled.Window width={rowWidth} headerHeight={HEADER_ROW_WIDTH} ref={windowWrapper} panelsCount={panelsCount}>
				<Styled.ContentBox height={visibleRowsHeight} width={rowWidth}>
					{visibleRows.slice(firstIndex, lastIndex).map(({ rowIndex, row, top }, index) => (
						<Styled.PositionedRow
							top={top}
							key={row[COLUMN_INDEXES.ID]}
							row={row}
							rowIndex={rowIndex}
							columns={columns}
							props={rowProps[row[COLUMN_INDEXES.ID]]}
							contextMenuHandler={contextMenuHandler}
							uniqueCategories={uniqueCategories}
							tableId={tableId}
							selected={getSelectedValue(rowIndex, rows, rowProps)}
							selectedCell={selectedCell}
							isParent={isParent(rowIndex, rows)}
							filteredRowIndex={filteredRowIndex}
						/>
					))}
					<SelectionWidget tableId={tableId} />
				</Styled.ContentBox>
			</Styled.Window>
		</Styled.Wrapper>
	);
}
